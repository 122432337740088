.app-container {
    display: flex;
    padding: 40px;
    height: 100%;

    .canvas-container {
        width: 40%;
    }

    .content-container {
        flex: 1;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
        padding-bottom: 30px;
    }

    .content-container::-webkit-scrollbar {
        display: none;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 10;
        flex-grow: 1;
        height: 70%;
        margin-top: 200px;
    }

    .header-img-container {    
        margin-bottom: -200px;
        max-width: 350px;
        .img {
            width: 100%;
            max-width: 350px;
        }
    }

}

@media only screen and (max-width: 600px) {
    .app-container {
        gap: 20px;
        height: auto;
        flex-direction: column;
        padding: 20px 10px;

        .canvas-container {
            width: 100%;
            height: 500px;
            margin-bottom: 80px;
        }

        .content-container {
            box-shadow: none;
            padding-bottom: 100px;
        }

        .loader {
            margin-top: 100px;
        }

        .header-img-container {
            text-align: center;
            max-width: 100%;
            margin-bottom: -80px;
        }
    }
}
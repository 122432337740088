.cards-container {
    width: 100%;

    .card {
        border-radius: 4px;
        padding: 0 20px;
        max-width: 800px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 1.5;
        color: #333;
        font-family: "Roboto", sans-serif;
        margin-bottom: 40px;
    }

    .card-title {
        font-size: 28px;
        font-weight: bold;
        color: #111;
        font-family: "Protest Strike", sans-serif;
        border-bottom: 2px solid #1a237e;
        display: inline-block;
        line-height: 1;
        margin-bottom: 10px;
        padding-bottom: 2px;
    }

    .work-title {
        display: flex;
    }

    .view-work {
        font-size: 16px;
        margin: 10px 0;
        display: inline-block;
        margin-left: 20px;
        padding: 0;
        border-bottom: 1px solid transparent;

        .icon {
            margin-left: 6px;
            font-size: 14px;
        }

        a {
            text-decoration: none;
            color: #1a237e;
        }
    }

    .view-work:hover {
        border-bottom: 1px solid #1a237e;
    }

    .card-sub-title {
        margin: 20px 0 0 40px;
    }

    .card-sub-body {
        margin-left: 40px;

        ul {
            padding-left: 0px;
            list-style: none;
            margin: 10px 0 0 0;
        }

        li {
            padding-bottom: 10px;
        }

        .list-header {
            font-weight: 600;
        }
    }

    .paragraph {
        margin-bottom: 10px;
    }

    .img-container {
        width: 140px;
    }

    .img {
        width: 100%;
        border-radius: 4px;
    }

    .footer {
        display: flex;
        font-size: 24px;
        border-top: 2px solid #666;
        padding-top: 30px;
        color: #333;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    .footer-text {
        // flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .social {
        display: flex;
        gap: 6px;
        align-items: center;

        a {
            font-size: 30px;
            color: #333;
        }
    }

    .link {
        text-decoration: none;
        color: #1a237e;
    }

    .link:hover {
        text-decoration: underline;
    }

    .first-word {
        font-size: 20px;
        font-weight: 800;
    }

    .work-detail {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .cards-container {
        .footer {
            display: flex;
            margin-bottom: 40px;
            gap: 10px;
        }

        .footer-text {
            align-items: center;
            text-align: center;
            font-size: 20px;
        }

        .img-container {
            width: 100px;
        }

        .card-sub-body,
        .card-sub-title {
            margin-left: 0;
        }
    }
}
.work-container {
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    background: #FFEEEE;

    .back-btn {
        padding: 10px 0 0 0;

        a {
            text-decoration: none;
            color: #1a237e;
        }

        a:hover {
            border-bottom: 1px solid #1a237e;
        }
    }

    .work-title {
        font-size: 3rem;
        text-align: center;
    }

    .work-content {
        // border: 2px solid;
        // border-radius: 6px;
        max-width: 1100px;
        // padding: 20px;
        margin: 0 auto;
        // box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        // background: white;
    }   
}
html,
body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/* https://uigradients.com/ */
#root {
    font-family: "Protest Strike", sans-serif;
    height: 100%;

    /* background: #ffb347;
    background: linear-gradient(to bottom, #ffcc33, #ffb347);
    background: -webkit-linear-gradient(to bottom, #ffcc33, #ffb347); */

    /* background: #ECE9E6;
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
    background: linear-gradient(to right, #FFFFFF, #ECE9E6); */

    background: #FFEEEE;
    background: -webkit-linear-gradient(to bottom, #DDEFBB, #FFEEEE);
    background: linear-gradient(to bottom, #DDEFBB, #FFEEEE);
}

@media only screen and (max-width: 600px) {
    #root {
        overflow-y: auto;
    }
}